/* ONLINE CHATBOX custom configuration goes here (www.olark.com/documentation) */
olark.configure('locale.welcome_title', 'Hai bisogno di aiuto?');
olark.configure('locale.chatting_title', 'Chat in corso');
olark.configure(
    'locale.welcome_message',
    "Benvenuto, compila i campi e inizia la chat oppure cerca nella sezione <a href='https://www.inwild.it/aiuto/faq' target='_blank'>Domande Frequenti</a>"
);
olark.configure(
    'locale.chat_input_text',
    'Scrivi e premi invio per iniziare la chat'
);
olark.configure(
    'locale.ended_chat_message',
    'La chat è terminata, se hai bisogno di ulteriori informazioni, scrivici nuovamente'
);
olark.configure('locale.end_chat_button_text', 'Termina chat');

/* OFFLINE CHATBOX custom configuration goes here (www.olark.com/documentation) */
olark.configure('locale.unavailable_title', 'Hai bisogno di aiuto?');
olark.configure(
    'locale.away_message',
    "Al momento gli operatori non possono rispondere, se hai bisogno di aiuto compila il modulo nella pagina <a href='https://www.inwild.it/aiuto#contactUs' target='_blank'>Aiuto</a>"
);
olark.configure('locale.name_input_text', 'Nome');
olark.configure('locale.email_input_text', 'Email');
olark.configure('locale.phone_input_text', 'Telefono');
olark.configure(
    'locale.offline_note_message',
    'Chat: offline. Lascia un messaggio'
);
olark.configure('locale.send_button_text', 'Invia');
olark.configure('locale.offline_note_sending_text', 'Invio...');
olark.configure(
    'locale.offline_note_thankyou_text',
    'Grazie per il messaggio! Vi risponderemo al più presto'
);
olark.configure(
    'locale.offline_note_error_text',
    "E' necessario completare tutti i campi e lasciare un indirizzo email valido"
);

/* PRE-CHAT SURVEY custom configuration goes here (www.olark.com/documentation) */
olark.configure('locale.name_input_text', 'Nome');
olark.configure('locale.email_input_text', 'Email');
olark.configure('locale.phone_input_text', 'Telefono');
olark.configure(
    'locale.introduction_error_text',
    "E' necessario completare tutti i campi e lasciare un indirizzo email valido."
);
olark.configure(
    'locale.introduction_messages',
    "Benvenuto, compila i campi e inizia la chat oppure cerca nella sezione <a href='https://www.inwild.it/aiuto/faq' target='_blank'>Domande Frequenti</a>"
);
olark.configure('locale.introduction_submit_button_text', 'Inizia la chat');
olark.configure('locale.prechat_survey_name_label', 'Nome');
olark.configure('locale.prechat_survey_name_placeholder', 'Nome');

/* CHAT RATINGS custom configuration goes here (www.olark.com/documentation) */
olark.configure(
    'locale.feedback_survey_question_chat_text',
    'Qual è il tuo grado di soddisfazione con questa chat?'
);
olark.configure(
    'locale.feedback_survey_question_operator_intelligence_text',
    'Qual era il livello di competenza dell’operatore della chat?'
);
olark.configure(
    'locale.feedback_survey_question_operator_speed_text',
    'Quanto sollecito è stato l’operatore della chat?'
);
olark.configure(
    'locale.feedback_survey_question_operator_attitude_text',
    'Quanto cordiale è stato l’operatore della chat?'
);
olark.configure('locale.feedback_survey_question_1_text', 'Domanda 1 di 5');
olark.configure('locale.feedback_survey_question_2_text', 'Domanda 2 di 5');
olark.configure('locale.feedback_survey_question_3_text', 'Domanda 3 di 5');
olark.configure('locale.feedback_survey_question_4_text', 'Domanda 4 di 5');
olark.configure('locale.feedback_survey_question_5_text', 'Domanda 5 di 5');
olark.configure(
    'locale.feedback_survey_question_chat_low',
    'Per niente soddisfatto/a'
);
olark.configure(
    'locale.feedback_survey_question_chat_high',
    'Estremamente soddisfatto/a'
);
olark.configure(
    'locale.feedback_survey_question_operator_intelligence_low',
    'Per niente competente'
);
olark.configure(
    'locale.feedback_survey_question_operator_intelligence_high',
    'Estremamente competente'
);
olark.configure(
    'locale.feedback_survey_question_operator_speed_low',
    'Per niente sollecito'
);
olark.configure(
    'locale.feedback_survey_question_operator_speed_high',
    'Estremamente sollecito'
);
olark.configure(
    'locale.feedback_survey_question_operator_attitude_low',
    'Per niente cordiale'
);
olark.configure(
    'locale.feedback_survey_question_operator_attitude_high',
    'Estremamente cordiale'
);
olark.configure(
    'locale.feedback_survey_question_additional_feedback_text',
    'Ulteriore feedback'
);
olark.configure('locale.feedback_survey_button_next', 'Prossima');
olark.configure('locale.feedback_survey_button_submitting', 'Inviando...');
olark.configure('locale.feedback_survey_button_finish', 'Fine');
olark.configure(
    'locale.feedback_survey_end_message',
    'Grazie per il Vostro feedback'
);
olark.configure(
    'locale.feedback_survey_submission_error_message',
    'Errore - feedback non inviato'
);
olark.configure(
    'locale.feedback_survey_begin_button_text',
    'Dai un punteggio alla chat'
);
olark.configure('locale.feedback_survey_complete_button_text', 'Fine');
olark.configure('locale.feedback_survey_cancel_text', 'Annulla');
olark.configure('locale.restart_chat_online_button_text', 'Inizia la chat');
olark.configure('locale.restart_chat_offline_button_text', 'Email');

/* SEND TRANSCRIPTS custom configuration goes here */
olark.configure('locale.send_transcript_title_text', 'Invia trascrizione');
olark.configure(
    'locale.send_transcript_begin_button_text',
    'Invia trascrizione'
);
olark.configure(
    'locale.send_transcript_complete_button_text',
    'Trascrizione inviata'
);
olark.configure(
    'locale.send_transcript_error_text',
    'Errore - trascrizione non inviata'
);
olark.configure('locale.send_transcript_cancel_text', 'Annulla');
olark.configure('locale.send_transcript_placeholder', 'Email');

/* OPERATOR custom configuration goes here */
olark.configure('locale.operator_is_typing_text', 'sta scrivendo...');
olark.configure(
    'locale.operator_has_stopped_typing_text',
    'ha smesso di scrivere'
);

olark.configure(
    'locale.disabled_input_text_when_convo_has_ended',
    'Chat terminata, premi aggiorna (F5) per una nuova chat'
);
olark.configure(
    'locale.disabled_panel_text_when_convo_has_ended',
    'La conversazione è terminata, per una nuova chat aggiorna la pagina (tasto F5)!'
);
